import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import './App.css'; // Assuming you have some global styles

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header /> {/* Your navigation bar */}
        <Routes> {/* Updated to use 'Routes' instead of 'Switch' */}
          {/* Setup your routes here */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          {/* You can add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
