import React from 'react';

const Home: React.FC = () => {
  return (
    <div className="hero">
    <h1>Transforming Healthcare Support</h1>
    <p>Your Trusted Partner for Virtual Healthcare Solutions</p>
    <button>Get Started</button>
  </div>
  );
};

export default Home;
